export const ENV = process.env.VUE_APP_ENV as 'dev' | 'prod' | 'uat'
export const APP_VERSION = process.env.VUE_APP_VERSION
export const MOTHERSHIP_URL = process.env.VUE_APP_MOTHERSHIP_URL
export const CHARTERING_OFFICES_URL =
  process.env.VUE_APP_CHARTERING_OFFICES_URL || ''
export const LAUNCH_DARKLY_KEY = process.env.VUE_APP_LAUNCH_DARKLY_KEY
export const LAUNCH_DARKLY_STATIC_KEY =
  process.env.VUE_APP_LAUNCH_DARKLY_STATIC_KEY
export const DATADOG_APP_ID = process.env.VUE_APP_DATADOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
export const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
export const AUTH0_USE_TOKEN_POPUP = ENV !== 'prod'
export const CARRIER_USER_SERVICE_URL =
  process.env.VUE_APP_CARRIER_USER_SERVICE_URL
export const CARRIER_USER_SERVICE_AUDIENCE =
  process.env.VUE_APP_CARRIER_USER_SERVICE_AUDIENCE

export const GTM_CONTAINER_ID = process.env.VUE_APP_GTM_CONTAINER_ID
export const IS_LOCALHOST = window.location.hostname === 'localhost'
export const TRANSLATIONS_URL = process.env.VUE_APP_TRANSLATIONS_URL
export const POEDITOR_API_TOKEN = 'c130a9b9ed1b9b5c5fba0392681d3c53'
export const POEDITOR_PROJECT_ID = '381747'

export const isLocalEnv = (): boolean => {
  return ENV === 'dev'
}

export function isUatEnv() {
  return ENV === 'uat'
}

export function isProductionEnv() {
  return ENV === 'prod'
}
